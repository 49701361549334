/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          //************************************Slider*************************************************************

          $('#slider.owl-carousel').owlCarousel({
              loop:true,
              items:1,
              margin:0,
              autoplay:true,
              animateOut: 'fadeOut',
              animateIn: 'fadeIn',
              navText:"",
              dots:true,
              nav:true,
              autoplayTimeout:8000
          });

          //************************************Mobile Menu*************************************************************

          $('#simple-menu').sidr({
              side: 'right'
          });

        //**************************************************************************************************************

          $(".grouped_elements").fancybox({
              'transitionIn'	: 'elastic',
              'transitionOut'	: 'elastic',
              'padding'         : 0,
              'speedIn'		    : 600,
              'speedOut'		: 200,
              'overlayShow'	    : true
          });

          //*******************************************************Full Screen Video***********************************

          var myVideo = document.getElementById('myVideo');
          var playButton = document.getElementById('playButton');
          var playButton2 = document.getElementById('playButton2');

          playButton.addEventListener('click', function () {
              if (myVideo.paused) {
                  if (myVideo.requestFullscreen) {
                      myVideo.requestFullscreen();
                  }
                  else if (myVideo.msRequestFullscreen) {
                      myVideo.msRequestFullscreen();
                  }
                  else if (myVideo.mozRequestFullScreen) {
                      myVideo.mozRequestFullScreen();
                  }
                  else if (myVideo.webkitRequestFullScreen) {
                      myVideo.webkitRequestFullScreen();
                  }
                  myVideo.play();
              }
              else {
                  myVideo.pause();
              }
          }, false);

          playButton2.addEventListener('click', function () {
              if (myVideo.paused) {
                  if (myVideo.requestFullscreen) {
                      myVideo.requestFullscreen();
                  }
                  else if (myVideo.msRequestFullscreen) {
                      myVideo.msRequestFullscreen();
                  }
                  else if (myVideo.mozRequestFullScreen) {
                      myVideo.mozRequestFullScreen();
                  }
                  else if (myVideo.webkitRequestFullScreen) {
                      myVideo.webkitRequestFullScreen();
                  }
                  myVideo.play();
              }
              else {
                  myVideo.pause();
              }
          }, false);

          jQuery(window).bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
              var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
              var event = state ? 'FullscreenOn' : 'FullscreenOff';
              if (event === "FullscreenOff") {
                  myVideo.pause();
              }
          });

          //************************************Video*******************************************************************


        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
